<template>
  <main id="site-wrapper">
    <section class="section">
      <div class="container-fluid">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a>Admin</a>
          </li>
          <li class="breadcrumb-item active">
            Device Setup
          </li>
        </ol>
        <div class="section-header">
          <div class="section-header-title">
            <router-link
              class="section-header-back"
              :to="{name: 'dashboard'}"
            >
              <i class="fas fa-arrow-circle-left" />
            </router-link>
            <h1>Device Setup</h1>
          </div>
        </div>
          <div class="col-lg-12">
            <div class="section-panel">
              <div class="section-panel-header">
                <div class="section-panel-header-title">
                  <h3>Scan QR code</h3>
                </div>
              </div>
              <div class="section-panel-body">
                          <QRCode
                            :input="qrinput"
                            class="code"
                          />
                </div>

            </div>
        </div>
      </div>
    </section>

  </main>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import QRCode from '@/components_v2/intelligent/branding/__COMP_QRCode.vue';

// @Component({
//   components: {
//     QRCode
//   },
// })

export default {
  name: 'DeviceSetup',
  data() {
    return {
      qrinput: Vue.prototype.$http.defaults.baseURL + "device-setup"
    }
  },
  components: {
    QRCode
  }
};
</script>
